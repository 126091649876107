import React from 'react'
import spec from '../../../swagger.json'
import { RedocStandalone } from 'redoc'
import { theme } from '../theme/theme'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled, {ThemeProvider} from 'styled-components'

const Wrapper = styled.div`
  min-height: 1024px;
`
const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <SEO title="API documentation" />
      <Wrapper>
        <RedocStandalone
          options={{
            nativeScrollbars: true,
            theme,
          }}
          spec={spec}
        ></RedocStandalone>
      </Wrapper>
    </Layout>
  </ThemeProvider>

)

export default IndexPage
