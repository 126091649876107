import styled from 'styled-components'

export const Wrapper = styled.footer`
  height: 126px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme?.colors?.header};
`

export const Row = styled.div`
  display: flex;
  font-size: 14px;

  span {
    margin: 0 8px;
    color: ${({ theme }) => theme?.colors?.white};
  }
`

export const Logo = styled.img`
  width: 94px;
  height: 25px;
`
