export const theme = {
  logo: {
    gutter: '16px',
  },
  sidebar: {
    width: '200px',
    backgroundColor: '#F4F5F7',
  },
  colors: {
    header: '#333646',
    white: '#FFF',
    primary: {
      main: '#252734',
    },
  },
  typography: {
    fontFamily: 'Averta Standard, sans-serif',
    fontDisplay: 'swap',
    headings: {
      fontFamily: 'Averta Standard, sans-serif',
      fontWeight: '500',

      lineHeight: '1.6em',
    },
    links: {
      color: '#45C7B4',
    },
  },
  spacing: {
    sectionVertical: 8,
  },
  rightPanel: {
    backgroundColor: '#424657',
  },
  codeBlock: {
    backgroundColor: '#333646',
  },
  text: {
    primary: '#252734',
  },
}
