import * as React from 'react'
import { Wrapper, Row, Logo } from './footer.styles'

import lightLogo from '../../images/detectify-logo-light.svg'

export const Footer = () => (
    <Wrapper>
      <Row>
        <Logo src={lightLogo} alt="Detectify logo" />
        <span>© 2021 detectify | Go hack yourself.</span>
      </Row>
    </Wrapper>
)
