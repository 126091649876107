import * as React from 'react'
import lightLogo from '../../images/detectify-logo-light.svg'
import { Link, Logo, StyledHeader } from './header.styles'

export const Header = () => (
    <StyledHeader>
      <Logo src={lightLogo} alt="Detectify logo" />
      <Link href="https://detectify.com/what-is-detectify">
        What is Detectify
      </Link>
      <Link href="https://detectify.com/pricing">Pricing</Link>
      <Link href="https://detectify.com/career">Career</Link>
      <Link href="https://blog.detectify.com/">Blog</Link>
    </StyledHeader>
)
