import styled from 'styled-components'

export const Link = styled.a`
  margin: 0.5rem 1rem;
  color: white;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    color: #45c7b4;
    transition: color 0.5s ease;
  }
`
export const StyledHeader = styled.header`
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme?.colors?.header};
  height: 120px;
  box-sizing: border-box;
`

export const Logo = styled.img`
  width: 142px;
  height: 37px;
  padding-right: 48px;
`
